import styled from 'styled-components';

export const Title = styled.h2`

    color: var(--black);
    margin: 25px auto;
  
`;

export const InputContainer = styled.div`
  margin-bottom: 10px;

  label {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
    color: var(--black);
    font-weight: bold;
  }

  @media (max-height: 700px) { 
    margin-bottom: 10px;
  }
`;

export const FormInput = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 10px;
  border: 1px solid var(--black);
  background-color: transparent;
  color: var(--black);

  @media (max-height: 700px) { 
      height: 30px;
    }

  &::placeholder {
    color: #888888; /* Cor do placeholder ajustada */
    opacity: 1;
  }

  &:focus {
    border-color: var(--bg-color2);
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  border-radius: 10px;
  border: 1px solid var(--black);
  background-color: transparent;
  color: var(--black);
  height: 100px;

  @media (max-height: 700px) { 
      height: 65px;
      padding: 10px;
  }

  &::placeholder {
    color: #888888; /* Cor do placeholder ajustada */
    opacity: 1;
  }

  &:focus {
    border-color: var(--bg-color2);
  }
`;

export const SubmitButton = styled.button`
  background-color: #555;
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  border-radius: 10px;
  border: none;
  background-image:linear-gradient(90deg, #ff8c00, #ff0080);
  color: white;
  cursor: pointer;

  &:hover {
    background-color: var(--bg-color1);
  }

  
`;

// Estilos para os ícones das redes sociais
export const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  margin:auto;
  flex-direction: column;

  div{
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SocialIconLink = styled.a`
  margin: 0 15px;
  color: var(--black);
  transition: color 0.3s ease;

  &:hover {
    color: var(--bg-color2);
  }
`;
