import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ContentWrapper = styled.div`
  flex: 1;
  max-width: 500px;
  margin: auto;
  color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  margin-bottom: 20px;
`;

export const CityInput = styled.input`
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  border-radius: 10px;
  border: 1px solid var(--black);
  background-color: transparent;
  color: var(--black);

  &::placeholder {
    color: var(--black);
    opacity: 1;
  }

  &:focus {
    border-color: var(--bg-color2);
  }
`;

export const TeamList = styled.div`
  height: calc(100vh - 180px);
  overflow-y: auto;
  padding: 10px 0;
  border: 1px solid transparent;
  border-radius: 10px;
  max-height: 650px;
`;

export const Bottom = styled.div`
  height: 60px;
  padding: 10px 0;
  margin-top: auto;
  border-top: 1px solid #555;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BottomIconWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const IconLink = styled(Link)<{ active?: boolean }>`
  color: var(--black);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    color: var(--bg-color2);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 3px;
    background: ${({ active }) => (active ? 'linear-gradient(90deg, #ff8c00, #ff0080)' : 'none')};
    border-radius: 15px;
  }
`;

export const TeamItem = styled.div`
  display: flex;
  align-items: center;
  margin: 7px;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 10px;
`;

export const Logo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const TeamInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TeamName = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  color: var(--black);
`;

export const TeamCity = styled.p`
  margin: 0;
  font-size: 1rem;
  color: var(--black);
`;

export const Loading = styled.div`
  text-align: center;
  margin: 20px 0;
  font-size: 1rem;
  color: #bbb;
`;
