import styled from 'styled-components';

export const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  margin:auto;
  flex-direction: column;

  div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
  }

  h4{
    display: flex;
    width: auto;
    align-items: center;
    justify-content: center;
    margin: 5px auto;
  }
`;

export const SocialIconLink = styled.a`
  margin: 0 15px;
  color: var(--black);
  transition: color 0.3s ease;

  &:hover {
    color: var(--bg-color2);
  }
`;