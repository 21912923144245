import React, { ReactNode } from 'react';
import { AppContainer } from './styles';

interface AppContainerProps {
  children: ReactNode; // Define o tipo para o children
}

const AppContainerComponent: React.FC<AppContainerProps> = ({ children }) => {
  return (
    <AppContainer>
      {children}
    </AppContainer>
  );
};

export default AppContainerComponent;
