import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  height: 100vh;
  background-color:var(--bg-color_white);
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const FieldInfo = styled.div`
  display: flex;
  align-items: center;
 

  h2{
    font-size: 20px;
    color:var(--black)
  }
`;

export const BackButton = styled.button`
  background-color: transparent;
  color: var(--black);
  border: none;
  font-size: 30px;
  cursor: pointer;
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const VideoPlayer = styled.video`
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  margin-bottom: 20px;
`;

export const DownloadButton = styled.a`
  background-color: #555;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;

  background-image:linear-gradient(90deg, #ff8c00, #ff0080);

  &:hover {
    background-color: #777;
  }
`;
