import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentWrapper, CityInput, TeamList, InputContainer, TeamItem, Logo, TeamInfo, TeamName, TeamCity } from './styles';
import { fetchFootballFields } from '../../service/apiService';
import { Field } from '../../types/types';
import BottomNavigation from '../../components/BottomNavigation';
import AppContainerComponent from '../../components/appContainer';
import ContentBox from '../../components/contentBox';

const Home: React.FC = () => {
  const [fields, setFields] = useState<Field[]>([]);
  const [filteredFields, setFilteredFields] = useState<Field[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const hasFetchedFields = useRef(false);

  const loadFields = useCallback(async () => {
    if (loading || !hasMore || hasFetchedFields.current) return;

    setLoading(true);
    try {
      const data = await fetchFootballFields();
      setFields((prevFields) => {
        const newFields = data.filter(
          (team) => !prevFields.some(
            (existingField) => existingField.name === team.name && existingField.city === team.city
          )
        );
        return [...prevFields, ...newFields];
      });
      setHasMore(data.length > 0);
      setLoading(false);
      hasFetchedFields.current = true;
    } catch (error) {
      console.error('Erro ao buscar os campos de futebol:', error);
      setLoading(false);
    }
  }, [loading, hasMore]);

  useEffect(() => {
    loadFields();
  }, []);

  useEffect(() => {
    const results = fields.filter((field) =>
      field.city.toLowerCase().includes(search.toLowerCase()) ||
      field.name.toLowerCase().includes(search.toLowerCase())
    );

    setFilteredFields(results);
  }, [search, fields]);

  const handleTeamClick = (field: Field) => {
    navigate(`/field/${field.name}`, { state: { field } });
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 50) {
      loadFields();
    }
  };

  return (
    <AppContainerComponent>
      <ContentBox>
        <InputContainer>
          <CityInput
            placeholder="Digite a cidade ou campo"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputContainer>
        <TeamList onScroll={handleScroll}>
          {filteredFields.map((field, index) => (
            <TeamItem key={index} onClick={() => handleTeamClick(field)}>
              <Logo src={field.logoUrl ? field.logoUrl : "https://via.placeholder.com/150"} alt={field.name} />
              <TeamInfo>
                <TeamName>{field.name}</TeamName>
                <TeamCity>{field.city}</TeamCity>
              </TeamInfo>
            </TeamItem>
          ))}
          {loading && <p style={{ display: "flex", justifyContent: "center", alignItems: "center", color:"black" }}>Carregando...</p>}
        </TeamList>

        <BottomNavigation />
      </ContentBox>
    </AppContainerComponent>
  );
};

export default Home;
