import React, { useState } from 'react';
import { InputContainer, FormInput, TextArea, SubmitButton, SocialMediaContainer, SocialIconLink, Title } from './styles';
import { sendTicketForm } from '../../service/apiService';
import BottomNavigation from '../../components/BottomNavigation';
import { formatPhoneNumber } from '../../utils/phone';

import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import AppContainerComponent from '../../components/appContainer';
import ContentBox from '../../components/contentBox';
import SocialMediaBox from '../../components/socialMediaBox';

const Contact: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setPhone(formattedPhone);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    await sendTicketForm(name, email, phone, message);
    setName('');
    setEmail('');
    setPhone('');
    setMessage('');
  };

  return (
    <AppContainerComponent>
      <ContentBox>
        <Title>Fale conosco</Title>
        <form onSubmit={handleSubmit}>
          <InputContainer>
            <label>Nome</label>
            <FormInput
              placeholder="Seu nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </InputContainer>
          <InputContainer>
            <label>Email</label>
            <FormInput
              type="email"
              placeholder="Seu e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </InputContainer>
          <InputContainer>
            <label>Celular</label>
            <FormInput
              type="tel"
              placeholder="Ex.: 99 999999999"
              value={phone}
              onChange={(e) => handlePhoneChange(e)}
              required
            />
          </InputContainer>
          <InputContainer>
            <label>Mensagem</label>
            <TextArea
              placeholder="Sua mensagem"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </InputContainer>
          <InputContainer>
            <SubmitButton type="submit">Enviar</SubmitButton>
          </InputContainer>
        </form>

        <SocialMediaBox title='Ou use uma de nossas redes sociais' />
        <BottomNavigation />
      </ContentBox>
    </AppContainerComponent>
  );
};

export default Contact;
