import React, { ReactNode } from 'react';
import { SocialMediaContainer, SocialIconLink } from './styles';
import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp } from 'react-icons/fa';

interface AppContainerProps {
  title: string;
  subtitle?: string;
}

const SocialMediaBox: React.FC<AppContainerProps> = ({ title, subtitle }) => {
  return (
    <SocialMediaContainer>
      <h4 >{title}</h4>
      {subtitle && <h4 >{subtitle}</h4>}
      <div>
        <SocialIconLink href="https://www.facebook.com/profile.php?id=61566588433810" target="_blank" aria-label="Facebook">
          <FaFacebook size={30} />
        </SocialIconLink>
        <SocialIconLink href="https://www.instagram.com/playmomentcx/" target="_blank" aria-label="Instagram">
          <FaInstagram size={30} />
        </SocialIconLink>
        <SocialIconLink href="https://x.com/playmomentcx" target="_blank" aria-label="Twitter">
          <FaTwitter size={30} />
        </SocialIconLink>
        <SocialIconLink href="https://wa.me/5522999594990?text=Olá,%20gostaria%20de%20mais%20informações!" target="_blank" aria-label="Whatsapp">
          <FaWhatsapp size={30} />
        </SocialIconLink>
      </div>
    </SocialMediaContainer>
  );
};

export default SocialMediaBox;
