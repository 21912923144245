// styles.ts (no mesmo diretório de BottomNavigation.tsx ou em um arquivo global de estilos)
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const BottomContainer = styled.div`
  height: 60px;
  padding: 10px 0;
  margin-top: auto;
  border-top: 1px solid #555;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BottomIconWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const IconLink = styled(Link)<{ active?: boolean }>`
  color: var(--black);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Necessário para o pseudo-elemento */

  &:hover {
    color: var(--bg-color2);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 3px;
    background: ${({ active }) => (active ? 'linear-gradient(90deg, #ff8c00, #ff0080)' : 'none')};
    border-radius: 15px; 
  }
`;
