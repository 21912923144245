import React from 'react';
import { useLocation } from 'react-router-dom';
import { AiFillHome, AiFillMail } from 'react-icons/ai';
import { BottomContainer, BottomIconWrapper, IconLink } from './styles';

const BottomNavigation: React.FC = () => {
  const location = useLocation(); // Hook para obter a rota atual

  return (
    <BottomContainer>
      <BottomIconWrapper>
        <IconLink to="/" active={location.pathname === '/'}>
          <AiFillHome size={30} />
        </IconLink>
        <IconLink to="">
          {/* Ícone 1 */}
        </IconLink>
        <IconLink to="">
          {/* Ícone 2 */}
        </IconLink>
        {/* <IconLink to="/mail" active={location.pathname === '/mail'}> */}
        <IconLink to="/mail" active={location.pathname === '/mail'} >
          <AiFillMail size={30} />
        </IconLink>
    </BottomIconWrapper>
    </BottomContainer >
  );
};

export default BottomNavigation;
